import Auth from '@aws-amplify/auth';
import { endpoint } from 'gsf-util-react-auth/lib/types';

// const ENV = process.env.REACT_APP_ENVIRONMENT || 'test'

const environment: string =
  process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? ''
    : `.${process.env.REACT_APP_ENVIRONMENT}` || '';

export const endpoints: endpoint[] = [
  {
    name: 'Projects',
    endpoint: `https://projects${environment}.api.goldspot.ca`,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        'Content-Type': 'application/json',
      };
    },
  },
  {
    name: 'Files',
    endpoint: `https://files${environment}.api.goldspot.ca`,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        'Content-Type': 'application/json',
      };
    },
  },
  {
    name: 'Company',
    endpoint: `https://company${environment}.api.goldspot.ca`,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        'Content-Type': 'application/json',
      };
    },
  },
];
