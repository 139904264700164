import en_app from './en-us/app.json';
import en_files from './en-us/files.json';
import en_general from './en-us/general.json';
import en_organization from './en-us/organization.json';
import en_projects from './en-us/projects.json';

// merge english sources
export const en = {
  translation: Object.assign({}, en_app, en_files, en_general, en_organization, en_projects),
};
