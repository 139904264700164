/** @mui/material imports */
/** @mui/icons-material imports */
/** React imports */
/** Goldspot imports */
import { AuthenticatedApiRequest } from 'gsf-util-react-auth';
import {
  GetProjectsListParams,
  ProjectObject,
  ProjectRequestObject,
  ProjectsList,
} from '../../TypeScript/interfaces';
import { ProjectObjectUpdate } from '../../TypeScript/types';

export const getProject = (
  company: string,
  projectId: string
): Promise<ProjectObject> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        const projectObject: ProjectObject = result;
        resolve(projectObject);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectsList = (
  company: string,
  queryParams?: GetProjectsListParams
): Promise<ProjectsList> => {
  return new Promise((resolve, reject) => {
    const params: string[] = Object.keys(queryParams || {}).map(
      (key: string): string => {
        const value: string = (queryParams || ({} as any))[key];
        if (typeof value === 'undefined' || value === null || value === '') {
          return '';
        } else {
          return `${key}=${value}`;
        }
      }
    );
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Projects',
      path: `/${company}?${params.join('&')}`,
    })
      .then((result: any) => {
        const projectsList: ProjectsList = result;
        resolve(projectsList);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createProject = (
  company: string,
  projectId: string,
  projectParams: ProjectRequestObject
): Promise<{ error?: string; message?: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'put',
      apiName: 'Projects',
      path,
      postData: {
        body: projectParams,
      },
    })
      .then((result: any) => {
        const response: { error?: string; message?: string } = result;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProject = (
  company: string,
  projectId: string,
  updatedProperties: Partial<ProjectObjectUpdate>
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'post',
      apiName: 'Projects',
      path,
      postData: {
        body: updatedProperties,
      },
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addUserToProject = (
  company: string,
  projectId: string,
  userId: string
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}/users/${userId}`;
    AuthenticatedApiRequest({
      method: 'put',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeUserFromProject = (
  company: string,
  projectId: string,
  userId: string
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path: string = `/${company}/${projectId}/users/${userId}`;
    AuthenticatedApiRequest({
      method: 'delete',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
